.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.headline {
    margin-bottom: 50px;
    font-family: 'Playfair Display';
    font-weight: 600;
    display: block;
    font-size: 38px;
}

.subheadline {
    margin-bottom: 30px;
    font-family: 'Playfair Display';
    font-weight: 300;
    display: block;
    font-size: 28px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.gallery-thumbnails--toggle.hide {
    display: none !important;
}

.gallery-modal .gallery-modal--overlay {
    background-color: rgba(0, 0, 0, 0.9) !important;
}
